// 工资表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="收款供应商">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款司机">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="付款单状态" prop="payStatus">
          <el-select class="item-choose"
                     v-model="dataForm.payStatus"
                     style="width: 100%;"
                     filterable
                     clearable placeholder="请选择">
            <el-option label="待付款" value="1"></el-option>
            <el-option label="队列中" value="0"></el-option>
            <el-option label="已付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="dataForm.createTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款时间">
          <el-date-picker
            v-model="dataForm.paymentTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="requestPay()" v-if="newAuth('request:order:add')" type="success">新增</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList" v-if="newAuth('request:order:wxPay')">
            <el-button size="small" @click="wxPay()" type="danger">支付</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox wages-table">
      <el-table :data="dataList"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :summary-method="getSummaries"
                show-summary
                :height="tableHeight"
                border size="small"
                v-loading="dataListLoading"
                @selection-change="handleSelectionChange"
                style="width: 100%;" class="table-input">
        <el-table-column type="selection" width="30" align="center" fixed="left"></el-table-column>
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column prop="requestNo" label="付款申请单号" width="90" align="center">
        </el-table-column>
        <el-table-column prop="payOrderType" :formatter="payRequestTypeConvent" label="付款单类型" width="75" align="center">
        </el-table-column>
        <el-table-column prop="payBasisSource" :formatter="payBasisSourceConvent" label="付款依据" width="70" align="center">
        </el-table-column>
        <el-table-column prop="motorcadeName" label="收款供应商" width="120" align="center">
        </el-table-column>
        <el-table-column prop="driverName" label="收款司机" width="70" align="center">
        </el-table-column>
        <el-table-column prop="requestPayAmount" label="申请总金额" width="75" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="付款申请说明" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="createName" label="创建人" width="80" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" width="130">
        </el-table-column>
        <el-table-column prop="payStatus" label="付款单状态" align="center" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.payStatus == 1" size="small" type="danger">待付款</el-tag>
            <el-tag v-if="scope.row.payStatus == 2" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.payStatus == 0" size="small" type="warning">队列中</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="payType" :formatter="payTypeConvent" label="付款方式" align="center" width="80">
        </el-table-column>
        <el-table-column prop="payUserName" label="付款人" align="center" width="80">
        </el-table-column>
        <el-table-column prop="payTime" label="付款时间" align="center" width="130">
        </el-table-column>
        <el-table-column fixed="right" align="center" width="80" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="requestPay(scope.row.id)">详情</el-button>
            <el-button type="text" size="small"
                       v-if="scope.row.payStatus == 1 && (newAuth('request:order:delete') || newAuth('request:order:deleteAll'))"
                       @click="deleteRow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <pay-request v-if="requestVisible" ref="payRequest" @refreshDataList="getDataList"></pay-request>
    <confirm-pay ref="confirmPay" @refreshDataList="getDataList"></confirm-pay>
  </div>
</template>

<script>

import PayRequest from './detail/pay-request-popup'
import ConfirmPay from './detail/confirm-pay-popup'

export default {
  data () {
    return {
      dataForm: {
        motorcadeName: null,
        driverName: null,
        createTime: null,
        paymentTime: null,
        payStatus: null
      },
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      requestVisible: false,
      payRequestTypeMap: null,
      payBasisSourceMap: null,
      payTypeMap: null,
      selectFeeData: []
    }
  },
  components: {
    PayRequest,
    ConfirmPay
  },
  created () {
    this.payRequestTypeMap = new Map()
    this.payRequestTypeMap.set(1, '往来')
    this.payRequestTypeMap.set(2, '冲账')
    this.payRequestTypeMap.set(3, '报销')
    this.payRequestTypeMap.set(4, '业务')
    this.payRequestTypeMap.set(5, '其他')
    this.payBasisSourceMap = new Map()
    this.payBasisSourceMap.set(1, '订单')
    this.payBasisSourceMap.set(2, '其他')
    this.payTypeMap = new Map()
    this.payTypeMap.set(1, '微信支付')
    this.payTypeMap.set(2, '银行转账')
    this.payTypeMap.set(3, '商家转账')
    this.getDataList()
    this.resetHeight()
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index !== 7) {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr).toFixed(2)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (requestPayAmount) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', requestPayAmount, '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    payRequestTypeConvent (row, column, cellValue, index) {
      return this.payRequestTypeMap.get(cellValue)
    },
    payBasisSourceConvent (row, column, cellValue, index) {
      return this.payBasisSourceMap.get(cellValue)
    },
    payTypeConvent (row, column, cellValue, index) {
      return this.payTypeMap.get(cellValue)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    resetHeight () {
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      window.setTimeout(() => {
        this.tableHeight = '67vh'
      }, 1000)
    },
    // 新增申请单
    requestPay (id) {
      this.requestVisible = true
      this.$nextTick(() => {
        this.$refs.payRequest.init(id)
      })
    },
    wxPay () {
      let ids = []
      let requestPayAmount = 0
      this.selectFeeData.forEach(item => {
        if (item.payStatus === 1 || item.payStatus === 0) {
          ids.push(item.id)
          requestPayAmount += item.requestPayAmount
        }
      })
      if (ids.length === 0) {
        this.$message.error('至少选择一条待付款数据')
        return false
      }
      this.$nextTick(() => {
        this.$refs.confirmPay.init(ids, requestPayAmount)
      })
    },
    downLoad () {
      let timeParam = {}
      if (this.dataForm.createTime && this.dataForm.createTime.length === 2) {
        timeParam.beginCreateTime = this.dataForm.createTime[0]
        timeParam.endCreateTime = this.dataForm.createTime[1]
      }
      if (this.dataForm.paymentTime && this.dataForm.paymentTime.length === 2) {
        timeParam.beginPaymentTime = this.dataForm.paymentTime[0]
        timeParam.endPaymentTime = this.dataForm.paymentTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/export/requestOrderExport`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'motorcadeName': this.dataForm.motorcadeName,
          'driverName': this.dataForm.driverName,
          'payStatus': this.dataForm.payStatus,
          'beginCreateTime': timeParam.beginCreateTime,
          'endCreateTime': timeParam.endCreateTime,
          'beginPaymentTime': timeParam.beginPaymentTime,
          'endPaymentTime': timeParam.endPaymentTime
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '付款申请表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let timeParam = {}
      if (this.dataForm.createTime && this.dataForm.createTime.length === 2) {
        timeParam.beginCreateTime = this.dataForm.createTime[0]
        timeParam.endCreateTime = this.dataForm.createTime[1]
      }
      if (this.dataForm.paymentTime && this.dataForm.paymentTime.length === 2) {
        timeParam.beginPaymentTime = this.dataForm.paymentTime[0]
        timeParam.endPaymentTime = this.dataForm.paymentTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/payRequest/findPayRequestOrderList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'motorcadeName': this.dataForm.motorcadeName,
          'driverName': this.dataForm.driverName,
          'payStatus': this.dataForm.payStatus,
          'beginCreateTime': timeParam.beginCreateTime,
          'endCreateTime': timeParam.endCreateTime,
          'beginPaymentTime': timeParam.beginPaymentTime,
          'endPaymentTime': timeParam.endPaymentTime
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.requestPayAmount)
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    deleteRow (row) {
      this.$confirm('确定删除单号【' + row.requestNo + '】', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/payRequest/deleteRequestOrder/' + row.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message.success('删除成功')
            this.getDataList()
          } else {
            this.$message.error('删除失败')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
  display: inline-block;
  margin-left: 10px;
}

.wages-table {
  td .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .el-input__inner {
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 5px !important;
    height: 24px !important;
    line-height: 24px !important
  }
}
</style>
